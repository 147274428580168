import { Link } from "gatsby"
import React, { useState, useEffect } from "react"
import { useLocation } from "@reach/router"
import {
  Navbar,
  Nav,
  Container,
  NavDropdown,
  Button,
  Offcanvas,
  Col,
  Row,
} from "react-bootstrap"

const Header = (props) => {
  const ignoreSrc=typeof window === "undefined"
  let [isDesktop, setIsDesktop] = useState(true)
  const checkScreenSize = () => {
    if (window.innerWidth >= 993) {
      setIsDesktop(true)
    } else {
      const offcanvasBackdrop = document.getElementsByClassName(
        "offcanvas-backdrop"
      )[0]
      const offcanvas = document.getElementById("offcanvasNavbar")

      offcanvasBackdrop && offcanvasBackdrop.classList.remove("show")
      offcanvas && offcanvas.classList.remove("show")
      setIsDesktop(false)
    }
  }

  const location = useLocation();
  useEffect(() => {
    checkScreenSize()
    window.addEventListener("resize", checkScreenSize, { passive: true })
  }, [])
  // const [modalShow, setModalShow] = React.useState(false);
  return (
    <>
      <Navbar
        collapseOnSelect
        expand="lg"
        // bg="light"
        variant="light"
        className="navbar-container"
        id='navbar-container'
        style={{
          padding: isDesktop ? "28px" : " 16px 20px ",
        }}
      >
        <Container>
          <Navbar.Brand href="https://cubo.to" className="brand-logo">
            <img
              src={ignoreSrc ? '':"/assets/icons/cubo-logo.png"}
              alt="brand-logo"
              width={100}
              style={isDesktop ? {} : { marginRight: 0 }}
            />
          </Navbar.Brand>

          {location.pathname != '/' &&
            <a
              href="/register"
              style={{ textDecoration: "none", marginLeft: "auto" }}
              id="freeTrialBtn"
            >
              <div className="freeTrialBtn">Try for Free &rarr;</div>
            </a>
          }
          

          <Navbar.Toggle
            aria-controls="offcanvasNavbar"
            style={{ opacity: 1, padding: "4px 0", border: "none" }}
          />

          <Navbar.Offcanvas
            id="offcanvasNavbar"
            aria-labelledby="offcanvasNavbarLabel"
            placement="end"
            className="mobile-nav"
          >
            <Offcanvas.Header closeButton>
              <Link to="/">
                <Offcanvas.Title
                  id="offcanvasNavbarLabel"
                  className="brand-logo"
                  style={{ margin: 0 }}
                >
                  <img
                    loading="lazy"
                    width={100}
                    height={40}
                    src={ignoreSrc ? '':"/assets/icons/cubo-logo.svg"}
                    alt="brand-logo"
                  />
                </Offcanvas.Title>
              </Link>
            </Offcanvas.Header>

            <Offcanvas.Body style={{ paddingTop: 0 }}>
              <Nav
                className="justify-content-end flex-grow-1 "
                style={{
                  fontWeight: 700,
                  fontSize: "16px",
                  lineHeight: "18px",
                }}
              >
                <Link
                  to="/"
                  className="nav-link mobileLink"
                  style={{ padding: "16px ", fontWeight: 700 }}
                >
                  HomePage
                </Link>
                <Link
                  to="/pricing/"
                  className="nav-link mobileLink"
                  style={{ padding: "16px ", fontWeight: 700 }}
                >
                  Pricing
                </Link>
                <Link
                  to="/about-us/"
                  className="nav-link mobileLink"
                  style={{ padding: "16px", fontWeight: 700 }}
                >
                  About
                </Link>
                <Link
                  to="/blog/"
                  className="nav-link mobileLink"
                  style={{ padding: "16px ", fontWeight: 700 }}
                >
                  Blog
                </Link>

                <Link
                  to="/contact-us/"
                  className="nav-link mobileLink"
                  style={{ padding: "16px ", fontWeight: 700 }}
                >
                  Contact
                </Link>
                <Link
                  to="/faq"
                  className="nav-link mobileLink"
                  style={{ padding: "16px ", fontWeight: 700 }}
                >
                  FAQ
                </Link>
                <Link
                  to="/download/"
                  className="nav-link mobileLink"
                  style={{ padding: "16px ", fontWeight: 700 }}
                >
                  Download
                </Link>
              </Nav>
            </Offcanvas.Body>
          </Navbar.Offcanvas>

          <Nav className="me-auto center-nav">
            <Link
              to="/pricing/"
              className="nav-link"
              style={{ margin: "0 16px", padding: "0", minWidth: "58px" }}
            >
              Pricing
            </Link>
            <Link
              to="/about-us/"
              className="nav-link"
              style={{ margin: "0 16px", padding: "0", minWidth: "50px" }}
            >
              About
            </Link>
            <Link
              to="/blog/"
              activeClassName="active"
              className="nav-link"
              style={{ margin: "0 16px", padding: "0", minWidth: "36px" }}
            >
              Blog
            </Link>
            <Link
              to="/download/"
              className="nav-link"
              style={{ margin: "0 16px", padding: "0", minWidth: "84px" }}
            >
              Download
            </Link>
            <Link
              to="/contact-us/"
              className="nav-link"
              style={{ margin: "0 16px", padding: "0", minWidth: "68px" }}
            >
              Contact
            </Link>

            <Link
              to="/faq/"
              className="nav-link"
              style={{ margin: "0 16px", padding: "0", minWidth: "60px" }}
            >
              FAQ
            </Link>
          </Nav>

          <Nav className="right-nav">
            <a href="/login">
              <div className="index-login-btn">Log in</div>
            </a>
            <a href="/register">
              <div className="index-register-btn">Try for Free &rarr;</div>
            </a>
          </Nav>
        </Container>
      </Navbar>
    </>
  )
}

export default Header
