import "./style.scss"
import React from "react"
import { Link } from "gatsby"

const FooterDisplayBlok = () => {
  const openMailTo = () => {
    if (window !== "undefined") {
      window.open("mailto:contact@cubo.to")
    }
  }

  return (
    <section className="footer-display-section">
      <div className="wrapper">
        <div className="content">
          <div className="brief">
            <div className="top">
              <Link to="/">
                <img
                  src={"/assets/icons/cubo-logo.svg"}
                  alt="brand-logo"
                  loading="lazy"
                  width={120}
                />
              </Link>
              <p className="slogan mb-hide">｜No.1 Online Office Software</p>
              <p className="slogan mb-show">No.1 Online Office Software</p>
            </div>
            <div className="des">
              At Cubo, we are a global team of passionate and talented people
              changing how businesses and people work online with our full,
              all-in-one platform.
            </div>
            <div className="icons">
              <a
                className="me-2"
                href="https://www.facebook.com/letscubo"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  loading="lazy"
                  src="/assets/icons/facebook.png"
                  alt="facebook-png"
                  width={28}
                  height={28}
                  style={{ borderRadius: "2px" }}
                />
              </a>
              <a
                className="me-2"
                href="https://twitter.com/letscubo"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  loading="lazy"
                  src="/assets/icons/tw.png"
                  alt="twitter-png"
                  width={28}
                  height={28}
                  style={{ borderRadius: "2px" }}
                />
              </a>
              <a
                href="https://www.linkedin.com/company/letscubo/"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  loading="lazy"
                  src="/assets/icons/linkedin.png"
                  alt="linkedin-png"
                  width={28}
                  height={28}
                  style={{ borderRadius: "2px" }}
                />
              </a>
            </div>
          </div>
          <div className="categorys">
            <div className="category">
              <div className="category-title">Product</div>
              <ul className="category-links-list">
                <li>
                  <Link to="/">Homepage</Link>
                </li>
                <li>
                  <Link to="/pricing/">Pricing</Link>
                </li>
                <li>
                  <Link to="/download/">Download</Link>
                </li>
              </ul>
            </div>
            <div className="category">
              <div className="category-title">Resources</div>
              <ul className="category-links-list">
                <li>
                  <Link to="/blog/">Blogs</Link>
                </li>
                <li>
                  <Link to="/about-us/">About us</Link>
                </li>
                <li>
                  <Link to="/faq/">FAQ</Link>
                </li>
              </ul>
            </div>
            <div className="category">
              <div className="category-title">Contact</div>
              <ul className="category-links-list">
                <li>
                  <Link to="/contact-us/">Contact us</Link>
                </li>
                <li onClick={openMailTo}>Contact@cubo.to</li>
              </ul>
            </div>
          </div>
        </div>
        <hr className="divide" />
        <div className="copyright-box" style={{ marginBottom: 80 }}>
          <div className="copyright">
            ©2023 Cubo World Inc. All rights reserved.
          </div>
          <div className="links">
            <span>
              <Link to="/termsofservice/">&nbsp;Terms of Service&nbsp;|</Link>
            </span>
            <span>
              <Link to="/privacypolicy/">&nbsp;Privacy Policy&nbsp;|</Link>
            </span>
            <span>
              <Link to="/sellinformation/">
                &nbsp;Do Not Sell My Personal Information&nbsp;|
              </Link>
            </span>
            <span>
              <Link to="/gdpr">&nbsp;GDPR&nbsp;</Link>
            </span>
          </div>
        </div>
      </div>
    </section>
  )
}
export default FooterDisplayBlok
